<template>
  <el-dialog
    title="新增排班"
    :visible.sync="dialogVisible"
    width="60%"
    :close-on-click-modal="false"
    @close="handleClose">
    <el-card>
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="岗位:" prop="jobs">
          <el-select @change="changeselect" v-model="form.jobs" placeholder="请选择活动区域">
            <el-option v-for="item in gangwei" :key="item.dictionaryId" :label="item.dictionaryName" :value="item.dictionaryCode"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名:" prop="name">
          <el-select v-model="form.name" placeholder="请选择工作人员">
            <el-option v-for="item in pops" :key="item.employeeId" :label="item.employeeName" :value="item.employeeId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="坐诊机构:" prop="companyId">
          <el-select v-model="form.companyId" @change="changeCompany" placeholder="请选择坐诊机构">
            <el-option v-for="item in jigou" :key="item.id" :label="item.companyName" :value="item.companyId"></el-option>
          </el-select>
        </el-form-item>
        <!-- <div> -->
          <el-form-item label="排班开始时间:" prop="date1">
            <el-col :span="12">
              <el-form-item>
                <el-date-picker @change="changedate1" type="date" :picker-options="expireTimeOption" placeholder="选择日期" v-model="form.date1" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="1"> -</el-col>
            <el-col :span="11">
             <el-form-item prop="date2">
                <el-select v-model="form.date2" @change="changeWorkTime">
                  <el-option v-for="(item,index) in workingTime" :key="index" :label="item.timing" :value="item.code"></el-option>
                </el-select>
             </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="可预约人数:" prop="number1">
            <el-input v-model="form.number1" placeholder="请输入可预约人数"></el-input>
          </el-form-item>
        <!-- </div> -->
      </el-form>
    </el-card>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="sureCommit('form')">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getAllInstitutionsInfo, postRequest } from 'src/api/index'
export default {
  data () {
    return {
      dialogVisible: true,
      form: {
        jobs: '',
        name: '',
        institutions: '',
        date1: '',
        number1: '',
        date2: '',
        number2: '',
        date3: '',
        date4: ''
      },
      rules: {
        jobs: [
          {required: true, message: '请选择岗位', trigger: 'change'}
        ],
        name: [
          {required: true, message: '请选择员工', trigger: 'change'}
        ],
        companyId: [
          {required: true, message: '请选择归属机构', trigger: 'change'}
        ],
        date1: [
          {required: true, message: '请选择排班日期', trigger: 'change'}
        ],
        date2: [
          {required: true, message: '请选择排班时间', trigger: 'change'}
        ],
        number1: [
          {required: true, message: '请输入可预约人数', trigger: 'blur'}
        ]
      },
      pops: [],
      jigou: [],
      startTime: '',
      endTime: '',
      date1: '',
      date2: '',
      gangwei: [],
      startTime: '',
      endTime: '',
      workingTime: [
        {
          timing: "09:00:00 - 10:00:00",
          code: 1
        },
        {
          timing: "10:00:00 - 11:00:00",
          code: 2
        },
        {
          timing: "11:00:00 - 12:00:00",
          code: 3
        },
        {
          timing: "13:00:00 - 14:00:00",
          code: 7
        },
        {
          timing: "14:00:00 - 15:00:00",
          code: 4
        },
        {
          timing: "15:00:00 - 16:00:00",
          code: 5
        },
        {
          timing: "16:00:00 - 17:00:00",
          code: 6
        },
      ],
      expireTimeOption: {
        disabledDate(date) {
          return date.getTime() <= Date.now();
        }
      },
    }
  },
  props: ['info'],
  mounted(){
    console.log(this.info, 'info')
    if (this.info != '') {
      this.form.companyId = Number(this.info.companyId)
      this.form.date1 = this.info.startTime.substring(0, 10)
      this.form.name = this.info.employeeId
      this.form.number1 = this.info.number
      this.form.date2 = Number(this.info.timeCode)
      this.startTime = this.info.startTime
      this.endTime = this.info.endTime
      this.form.jobs = this.info.employeeDuty
      postRequest('Employee/queryEmployee/',{employeeDuty: this.form.jobs}).then(res=>{
        console.log(res, '123')
        this.pops = res
      })
    }
    let data123 = {}
    getAllInstitutionsInfo(data123).then(res=>{
      console.log(res, 'jigou')
      this.jigou = res
    })
    postRequest('/dict/getDictByCategory/', {dictionaryCategoryCode: '03'}).then(res=>{
      console.log(res, 'xiaolei')
      this.gangwei = res
    })
  },
  methods: {
    handleClose () {
      this.$emit('closeAddScheduling')
    },
    changedate1(){
      console.log(this.form.date1)
      if (this.form.date1) {
        this.date1 = this.form.date1.getFullYear() + '-' + (this.form.date1.getMonth() + 1) + '-' + this.form.date1.getDate()
        console.log(this.date1)
      }
    },
    changeCompany(val){
      console.log(val)
      this.$forceUpdate()
      console.log(this.form.companyId)
    },
    changeselect(){
      this.form.name = ''
      console.log(this.form.jobs)
      if (this.form.jobs == '0301') {
        this.doctorTime = true
        this.nurseTime = false
      }
      if (this.form.jobs == '0302') {
        this.doctorTime = false
        this.nurseTime = true
      }
      postRequest('Employee/queryEmployee/',{employeeDuty: this.form.jobs}).then(res=>{
        console.log(res, '123')
        this.pops = res
      })
    },
    changeWorkTime(val){
      this.workingTime.map(item=>{
        if (item.code == val) {
          console.log(item.timing)
          this.startTime = item.timing.substring(0,8)
          console.log(this.startTime)
          this.endTime = item.timing.substring(10, 19)
          console.log(this.endTime)
        }
      })
    },
    sureCommit (form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.info != '') {
            console.log(this.startTime, this.endTime, 'tijiao')
            let data = {
              employeeId: this.form.name,
              startTime: this.date1 + ' ' + this.startTime,
              endTime: this.date1 + ' ' + this.endTime,
              workDay: '',
              number: this.form.number1,
              surplusNum: "",
              companyId: this.form.companyId,
              schedulId: this.info.schedulId,
              timeCode: this.form.date2
            }
            console.log(data)
            postRequest('/schedul/update/', data).then(res=>{
              console.log(res)
              this.$message({
                message: '修改成功',
                type: 'success'
              })
              this.handleClose()
            })
          }
          if (this.info == '') {
            let data = {
              employeeId: this.form.name,
              startTime: this.date1 + ' ' + this.startTime,
              endTime: this.date1 + ' ' + this.endTime,
              workDay: '',
              number: this.form.number1,
              surplusNum: "",
              companyId: this.form.companyId,
              timeCode: this.form.date2
            }
            console.log(data)
            postRequest('/schedul/addSave/', data).then(res=>{
              console.log(res)
              this.$message({
                message: '添加成功',
                type: 'success'
              })
              this.handleClose()
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor, .el-select {
  width: 100% !important;
}
.el-card {
  margin-top: -25px;
}
.el-form-item .el-form-item {
  margin-bottom: 0 !important;
}
</style>