<template>
  <div class="organ-management-page">
    <el-row>
      <el-col :span="24">
        <el-card shadow="always">
          <el-form :inline="true" :model="searchFrom" class="demo-form-inline search searchFrom" size="small">
            <el-form-item label="员工姓名:">
              <el-input v-model="searchFrom.organizationName" :clearable="true" placeholder="请输入员工名称"></el-input>
            </el-form-item>
            <el-form-item label="值班时间:">
              <el-date-picker
                @change="changedate"
                value-format="yyyy-M-dd"
                type="date"
                placeholder="选择日期"
                v-model="searchFrom.date1"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="机构:">
              <el-select v-model="searchFrom.companyCode" placeholder="请选择机构">
                <el-option label="全部 " value=""></el-option>
                <el-option
                  v-for="item in options"
                  :key="item.companyId"
                  :label="item.companyName"
                  :value="item.companyId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="员工类别:">
              <el-select clearable v-model="searchFrom.region" placeholder="请选择员工类别">
                <el-option label="全部 " value=""></el-option>
                <el-option
                  v-for="item in gangwei"
                  :key="item.dictionaryId"
                  :label="item.dictionaryName"
                  :value="item.dictionaryCode"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button icon="el-icon-search" type="primary" :disabled="disabledSearch" @click="getAllSchedul(1)">查询</el-button>
            </el-form-item>
            <el-form-item>
              <el-button plain icon="el-icon-plus" type="primary" @click="addNewSchedul">添加</el-button>
            </el-form-item>
            <el-form-item>
              <el-button plain icon="el-icon-upload2" type="primary" @click="importScheduling">导入</el-button>
            </el-form-item>
            <el-form-item>
              <el-button plain icon="el-icon-setting" type="primary" @click="updateNew">修改</el-button>
            </el-form-item>
            <el-form-item>
              <el-button plain icon="el-icon-delete" @click="handleDelete" type="danger">删除</el-button>
            </el-form-item>
          </el-form>
          <!-- <el-form :inline="true" :model="searchFrom" class="demo-form-inline searchFrom" size="small">
            <el-form-item>
              <el-button icon="el-icon-search" type="primary" :disabled="disabledSearch" @click="getAllSchedul">查询</el-button>
            </el-form-item>
            <el-form-item>
              <el-button plain icon="el-icon-plus" type="primary" @click="addNewSchedul">添加</el-button>
            </el-form-item>
            <el-form-item>
              <el-button plain icon="el-icon-upload2" type="primary" @click="importScheduling">导入</el-button>
            </el-form-item>
            <el-form-item>
              <el-button plain icon="el-icon-setting" type="primary" @click="updateNew">修改</el-button>
            </el-form-item>
            <el-form-item>
              <el-button plain icon="el-icon-delete" @click="handleDelete" type="danger">删除</el-button>
            </el-form-item>
          </el-form> -->
        </el-card>
      </el-col>
    </el-row>
    <el-card shadow="always" class="card">
      <div class="table-block">
        <div class="schedul">
          <div class="scheduling">
            <div class="title">
              <div>
                <span>{{ month }}月{{ day }}日</span>
                &nbsp;
              </div>
              <div>
                <el-select clearable @change="selectchange" v-model="searchFrom.region1" placeholder="请选择员工类别">
                  <el-option
                    v-for="item in gangwei"
                    :key="item.dictionaryId"
                    :label="item.dictionaryName"
                    :value="item.dictionaryCode"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div>
              <div class="infomation">
                <div class="rili">
                  <el-calendar v-model="value">
                  </el-calendar>
                </div>
                <div class="table">
                  <div class="Selectnum">已选中<span style="color:#5479FF">{{multipleSelection.length}}</span>条信息</div>
                  <el-table :data="tableData" v-loading="loading" ref="multipleTable" @row-click="handleClickTableRow" :header-cell-style="{'text-align': 'center', 'background': '#EEF1FC', 'color': '#656565'}" :cell-style="{'text-align':'center'}" @selection-change="handleSelectionChange">
                    <el-table-column type="selection" fixed width="55 "> </el-table-column>
                    <el-table-column prop="employeeName" label="人员姓名" width="90"> </el-table-column>
                    <el-table-column prop="timeCode1" label="9:00-10:00"> </el-table-column>
                    <el-table-column prop="timeCode2" label="10:00-11:00"> </el-table-column>
                    <el-table-column prop="timeCode3"  label="11:00-12:00"> </el-table-column>
                    <el-table-column prop="timeCode7" label="13:00-14:00"> </el-table-column>
                    <el-table-column prop="timeCode4"  label="14:00-15:00"> </el-table-column>
                    <el-table-column prop="timeCode5"  label="15:00-16:00"> </el-table-column>
                    <el-table-column prop="timeCode6" label="16:00-17:00"> </el-table-column>
                  </el-table>
                  <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="10"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                  </el-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <addNewScheduling :info="info" v-if="showAddScheduling" @closeAddScheduling="closeAddScheduling"></addNewScheduling>
    <importScheduling @closeImportScheduling="closeImportScheduling" v-if="isShow"></importScheduling>
  </div>
</template>

<script>
import { getAllInstitutionsInfo, postRequest } from 'src/api/index'
import addNewScheduling from '@/views/appointmentManagement/components/addNewScheduling'
import importScheduling from '@/views/appointmentManagement/components/importScheduling'
import { formatTime } from '@/utils/utils'
export default {
  data() {
    return {
      searchFrom: {
        organizationName: '',
        organizationCode: '',
        data1: '',
        region: '',
        region1: '',
      },
      tableData: [],
      multipleSelection: [],
      showAddScheduling: false,
      isShow: false,
      value: new Date(),
      month: '',
      day: '',
      week: '',
      weeks: '',
      type: 1,
      options: [],
      gangwei: [],
      multipleSelection: [],
      info: '',
      pageNo: 1,
      pageSize: 10,
      total: null,
      disabledSearch: false,
      loading: true,
      riliTime: ''
    }
  },
  mounted() {
    let date = new Date()
    let year = date.getFullYear()
    this.month = date.getMonth() + 1
    this.day = date.getDate()
    this.week = date.getDay()
    // 获取机构列表
    let data123 = {}
    getAllInstitutionsInfo(data123).then(res => {
      this.options = res
    })
    // 获取岗位列表
    postRequest('/dict/getDictByCategory/', { dictionaryCategoryCode: '03' }).then(res => {
      this.gangwei = res
    })
    // 旧接口
    this.getAllSchedul()
  },
  components: { addNewScheduling, importScheduling },
  watch: {
    value(newval, oldval) {
      if (newval) {
        let newYear = newval.getFullYear()
        this.month = (newval.getMonth() + 1)
        this.day = newval.getDate()
        let newTime = newYear + '-' + this.month + '-' + this.day
        // let data = {
        //   schedulTime: newTime,
        //   employeeName: '',
        //   companyCode: '',
        //   employeeDuty: '',
        //   pageNo: this.pageNo,
        //   pageSize: this.pageSize
        // }
        this.riliTime = newTime
        // this.searchFrom.date1 = newTime
        this.getAllSchedul()
      }
    },
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val
      this.getAllSchedul()
    },
    // 点击行选中
    handleClickTableRow(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    // 选中项
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleCurrentChange(val) {
      this.pageNo = val
      this.getAllSchedul()
    },
    closeAddScheduling() {
      this.showAddScheduling = false
      this.searchFrom.date1 = formatTime(new Date())
      this.getAllSchedul()
    },
    importScheduling() {
      this.isShow = true
    },
    closeImportScheduling() {
      this.isShow = false
      this.searchFrom.date1 = formatTime(new Date())
      this.getAllSchedul()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    addNewSchedul() {
      this.showAddScheduling = true
      this.info = ''
    },
    updateNew() {
      if (this.multipleSelection.length > 1) {
        this.$message({
          message: '只能选择一条进行修改',
          type: 'warning',
        })
      } else if (this.multipleSelection.length == 0) {
        this.$message({
          message: '请选择一项数据进行修改',
          type: 'warning',
        })
      } else if (this.multipleSelection.length == 1) {
        let data = {
          schedulId: this.multipleSelection[0].schedulId,
        }
        postRequest('/schedul/querySchedul/', data).then(res => {
          this.info = res[0]
          this.showAddScheduling = true
        })
      }
    },
    // 删除接口
    handleDelete() {
      if (this.multipleSelection.length <= 0) {
        this.$message({
          message: '请选择删除项',
          type: 'warning'
        })
      } else {
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          // this.multipleSelection.map(item => {
          //   let data = {
          //     schedulId: item.schedulId,
          //   }
          //   postRequest('/schedul/delete/', data).then(res => {
          //     console.log(res)
          //     this.$message({
          //       message: '删除成功',
          //       type: 'success',
          //     })
          //     this.getAllSchedul()
          //   })
          // })
          postRequest('/schedul/delete/', this.multipleSelection).then(res => {
            this.$message({
              message: '删除成功',
              type: 'success',
            })
            this.getAllSchedul()
          })
        }).catch(() => {
          this.$message({
            type: 'warning',
            message: '已取消删除'
          });
        });
      }
    },
    selectchange(val) {
      this.searchFrom.region= val
      this.getAllSchedul()
    },
    changedate() {
      this.$forceUpdate()
      this.riliTime = ''
    },
    getAllSchedul(no) {
      this.disabledSearch = true
      this.loading = true
      let now = new Date()
      let nowTime = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate()
      let data = {
        // schedulTime: this.searchFrom.date1 ? this.searchFrom.date1 : nowTime,
        schedulTime: this.riliTime || this.searchFrom.date1 || nowTime,
        employeeName: this.searchFrom.organizationName ? this.searchFrom.organizationName : '',
        companyCode: this.searchFrom.companyCode ? this.searchFrom.companyCode : '',
        employeeDuty: this.searchFrom.region ? this.searchFrom.region : '',
        pageNo: no ? no : this.pageNo,
        pageSize: this.pageSize
      }
      postRequest('/schedul/querySchedulPage/', data).then(res => {
        this.loading = false
        this.disabledSearch = false
        this.total = res.count
        res.data.map(item => {
          if (item.timeCode == 1) {
            item.timeCode1 = item.number + '人可预约'
          } else if (item.timeCode == 2) {
            item.timeCode2 = item.number + '人可预约'
          } else if (item.timeCode == 3) {
            item.timeCode3 = item.number + '人可预约'
          } else if (item.timeCode == 4) {
            item.timeCode4 = item.number + '人可预约'
          } else if (item.timeCode == 5) {
            item.timeCode5 = item.number + '人可预约'
          } else if (item.timeCode == 6) {
            item.timeCode6 = item.number + '人可预约'
          } else if (item.timeCode == 7) {
            item.timeCode7 = item.number + '人可预约'
          }
        })
        this.tableData = res.data
        res.data.map(item => {
          item.startTime = item.startTime.substring(0,10) + ' ' + item.startTime.substring(11,19)
          item.endTime = item.endTime.substring(0,10) + ' ' + item.endTime.substring(11,19)
        })
      }).catch(()=>{
        this.loading = false
        this.disabledSearch = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.organ-management-page {
  background: transparent;
}
.schedul {
  display: flex;
  justify-content: space-between;
  .calendar {
    width: 350px;
    .el-calendar {
      width: 350px;
      .el-calendar-table .el-calendar-day {
        height: 0 !important;
      }
    }
  }
}
.scheduling {
  padding: 5px;
  width: 100%;
  .title {
    padding: 20px 10px;
    display: flex;
    justify-content: space-between;
    background: #fff;
  }
}
.calendar {
  border-right: 1px solid #999;
}
.infomation {
  display: flex;
  justify-content: space-between;
  .rili {
    width: 30%;
  }
  .table {
    width: 67%;
  }
}
.el-calendar-table .el-calendar-day {
  height: 40px !important;
}
.el-calendar-table .el-calendar-day {
  height: 100px !important;
}
.el-calendar-table td.is-selected {
  background: #5479FF !important;
}
.Selectnum{
  padding:6px 30px;
  border:1px solid #BFCBF4;
  font-size: 12px;
  color:#666;
  border-radius: 5px;
  margin-bottom:10px;
}
</style>
