<template>
  <el-dialog
    title="上传文件"
    :visible.sync="dialogVisible"
    width="40%"
    v-loading="loading"
    :close-on-click-modal="false"
    @close="handleClose">
      <el-card>
        <el-upload
          style="text-align: center;"
          ref="uploadExcel"
          :limit=limitNum
          :action="action"
          :auto-upload="false"
          accept=".xlsx"
          :before-upload="beforeUploadFile"
          :on-change="fileChange"
          :on-exceed="exceedFile"
          :on-success="handleSuccess"
          :on-error="handleError"
          :file-list="fileList">
          <el-button size="small" plain>选择文件</el-button>
          <div slot="tip" class="el-upload__tip">只能上传xlsx(Excel2007)文件，且不超过1M</div>
        </el-upload>
      </el-card>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleClose123">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import axios from 'axios'
import {fileaction} from 'src/utils/cities'
export default {
  data () {
    return {
      dialogImageUrl: '',
        dialogVisible: false,
        disabled: false,
      dialogVisible: true,
      imageUrl: '',
      limitNum: 1,
      formLabelWidth: '80px',
      form: {
        file: ''
      },
      fileList: [],
      loading: false,
      action: fileaction,
      result: ''
    }
  },
  props: ['url'],
  mounted(){
    this.action = this.action + this.url
  },
  computed: {
    uploadUrl () {
      return fileaction + this.url
    }
  },
  methods: {
    handleClose(){
      this.$emit('closeUpload', [])
    },
    handleClose123(){
      this.$emit('closeUpload', this.result)
    },
    // 文件超出个数限制时的钩子
    exceedFile(files, fileList) {
      this.$notify.warning({
        title: '警告',
        message: `只能选择 ${this.limitNum} 个文件，当前共选择了 ${files.length + fileList.length} 个`
      });
    },
    // 文件状态改变时的钩子
    fileChange(file, fileList) {
      this.loading = true
      console.log('change')
      console.log(file)
      this.form.file = file.raw
      console.log(this.form.file)
      console.log(fileList)
      let formData = new FormData()
      formData.append('file', this.form.file)
      axios.post(fileaction + this.url + `?computerToken=${JSON.parse(localStorage.getItem('newToken')).computerToken}`,
        formData,
        { "Content-Type": "multipart/form-data" }
      )
      .then(res => {
        this.loading = false
        console.log('res')
        console.log(res)
        if (res.data.code == 200) {
          this.$message({
            message: '文件上传成功',
            type: 'success'
          })
          this.result = res.data.data
        } else {
          let mes = res.data.msg
          this.$message({
            message: mes,
            type: 'warning'
          })
        }
        
      })
      .catch(err => {
        this.loading = false
        console.log(err)
      })
    },
    // 上传文件之前的钩子, 参数为上传的文件,若返回 false 或者返回 Promise 且被 reject，则停止上传
    beforeUploadFile(file) {
      debugger
      console.log('before upload')
      console.log(file)
      let extension = file.name.substring(file.name.lastIndexOf('.')+1)
      let size = file.size / 1024 / 1024
      if(extension !== 'xlsx') {
        this.$notify.warning({
          title: '警告',
          message: `只能上传Excel2017（即后缀是.xlsx）的文件`
        });
      }
      if(size > 10) {
        this.$notify.warning({
          title: '警告',
          message: `文件大小不得超过10M`
        });
      }
    },
    // 文件上传成功时的钩子
    handleSuccess(res, file, fileList) {
      alert(res, file, fileList,'上传成功')
      console.log(res, '文件上传成功')
      this.$notify.success({
        title: '成功',
        message: `文件上传成功`
      });
    },
    // 文件上传失败时的钩子
    handleError(err, file, fileList) {
      console.log(err, file, fileList, '上传失败')
      this.$message({
        message: '文件上传失败',
        type: 'error'
      });
    },
    uploadFile() {
      this.$refs.uploadExcel.submit()
      let formData = new FormData()
      formData.append('file', this.form.file)
      axios.post(fileaction + this.url,
        formData,
        { "Content-Type": "multipart/form-data" }
      )
      .then(res => {
        console.log('res')
        console.log(res)
      })
      .catch(err => {
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    
  }
  .avatar-uploader {
    text-align: center !important;
  }
  // .el-dialog__body {
  //   padding: 0px 20px !important;
  // }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  // .el-dialog__body {
  //   text-align: center !important;
  // }
</style>