<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="60%"
    :close-on-click-modal="false"
    @close="handleClose">
    <el-card>
      <div class="button">
        <div type="primary" ><a :href="downloadscheduling">下载模版</a></div>
        <div type="primary" @click="showImportscheduling">导入</div>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%">
        <el-table-column label="导入模版">
          <el-table-column
            prop="name"
            label="姓名">
          </el-table-column>
          <el-table-column
            prop="employeeDuty"
            label="岗位"
            width="120">
          </el-table-column>
          <el-table-column
            prop="date"
            label="年月">
          </el-table-column>
          <el-table-column
            prop="time"
            label="时间段">
          </el-table-column>
          <el-table-column
            prop="number"
            label="可预约人数">
          </el-table-column>
        </el-table-column>
      </el-table>
    </el-card>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span>
    <uploadFile :url="url" v-if="isShow" @closeUpload="closeUpload"></uploadFile>
  </el-dialog>
</template>

<script>
import uploadFile from '@/views/customerManagement/component/uploadFile'
import {filedownaction} from 'src/utils/cities'
export default {
  data () {
    return {
      tableData: [],
      dialogVisible: true,
      url: '',
      isShow: false,
      downloadscheduling: filedownaction + '?type=workforceManagement'
    }
  },
  components: { uploadFile },
  methods: {
    handleClose() {
      this.$emit('closeImportScheduling') // schedul/parseSchedulExcel
    },
    showImportscheduling(){
      this.url = '/schedul/parseSchedulExcel'
      this.isShow = true
    },
    closeUpload(val){
      this.isShow = false
      console.log(val)
      
      this.tableData = val
    },
  }
}
</script>

<style lang="scss" scoped>
.button {
  margin-bottom: 20px;
  display: flex;
  >div {
    padding: 5px 20px;
    background: #eef1ff;
    color: #5967ff;
    border: 1px solid #5967ff;
    border-radius: 5px;
    margin-right: 10px;
    a {
      text-decoration: none;
      color: #5967ff;
    }
  }
}
</style>